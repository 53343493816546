import React, { useState, useEffect } from "react";
// import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Drawer, List, ListItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const SideBar = () => {
  const [activeSidebarItem, setActiveSidebarItem] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   if (isMobile) {
  //     setOpen(false);
  //   } else {
  //     setOpen(true);
  //   }
  // }, [isMobile]);
  useEffect(() => {
    console.log(location.pathname.split("/")[1] == "view");
    const activeRoute = routes.find((route) => route.href === location.pathname);
    if (activeRoute) {
      setActiveSidebarItem(activeRoute.id);
    } else if (location.pathname.split("/")[1] == "view") {
      setActiveSidebarItem(2)
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log(activeSidebarItem);
  })

  const routes = [
    { href: "/", name: "Dashboard", id: 1, icon: "fa-solid fa-house me-1 " },
    { href: "/coupons", name: "Coupon", id: 2, icon: "fa fa-credit-card  me-1" },
    { href: "/amount", name: "Amount", id: 3, icon: "fa-solid fa-user  me-1" },
    // { href: "/dispatch-coupon", name: "Dispatch Coupon", id: 4, icon: "fa fa-credit-card  me-1" }
  ];

  return (
    <div className="mc-sidebar">
      <div className="mc-sidebar-wrapper">
        <img
          src="/images/almond-logo.png"
          alt="logo-image "
          className="mc-logo"
        />
        <ul>
          {routes.map((e) => {
            return (
              <>

                <li key={e.id} className={activeSidebarItem === e.id ? "list-background" : "no-active"}
                >

                  <a onClick={() => {
                    navigate(e.href);
                  }}
                  >
                    <i className={e.icon} ></i>
                    <span > {e.name}</span>
                  </a>
                </li>
              </>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
