import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import convertStatusCode from "../services/convertStatusCode";

function CouponCard({ coupon }) {
  const [couponData, setCouponData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  let userData = null;

  useEffect(() => {
    if (coupon) {
      const array = Object.keys(coupon);
      const mapped = array.map((e, index) => {
        // console.log("e",e, index)
        if(e === "user"){
          userData = coupon[e]
        }
        let value = coupon[e];
        if (
          e === "created_at" ||
          e === "updated_at" ||
          e === "expireAt" ||
          e === "publishDate" ||
          e === "qwickcilver_expiry"
        ) {
          value = formatDate(value);
        } else if (e === "image") {
          setImageData(value);
          return null;
        }
        const obj = { label: e, value };
        return obj;
      });
      console.log("mapeed", mapped, "cou[on", coupon)
      // setCouponData(
      //   mapped.filter(
      //     (item) =>

      //       item !== null && item.value !== undefined && item.value !== null
      //   )
      // )


    }
    // console.log("userData", userData)
    setUsersData(userData);
    setCouponData(coupon);
  }, [coupon]);

  

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const handleOpenImage = () => {
    setShowFullImage(true);
  };
  const handleCloseImage = () => {
    setShowFullImage(false);
  };
  return (
    <div className="container">
          {console.log("dataaa", couponData?.[0]?.label)}
          
          <div className="coupon_row">
            <div className="coupon-card cards_coupons">
              <div className="coupon-data-container">
                <div className="row text-center"> <h3>Product Details</h3></div>

                <div className="attribute-card">
                  <div className="attribute-label">SKU </div>

                  <div className="attribute-value">{couponData?.sku?couponData.sku:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Category Name </div>

                  <div className="attribute-value">{couponData?.category_name?couponData.category_name:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Sub Category </div>

                  <div className="attribute-value">{couponData?.sub_category_name?couponData.sub_category_name:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Title </div>

                  <div className="attribute-value">{couponData?.title?couponData.title:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Product Name </div>

                  <div className="attribute-value">{couponData?.product_name?couponData.product_name:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Brand Name </div>

                  <div className="attribute-value">{couponData?.brand_name?couponData.brand_name:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Specification </div>

                  <div className="attribute-value">{couponData?.specification?couponData.specification:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">MRP </div>

                  <div className="attribute-value">{couponData?.cost?couponData.cost:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Discount </div>

                  <div className="attribute-value">{couponData?.discount?couponData.discount:0}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Amount </div>

                  <div className="attribute-value">{couponData?.price_point?couponData.price_point:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  {/* <div className="attribute-card">
              <div className="attribute-label">Image</div>
              <div>
                <button onClick={handleOpenImage} className="view-btn">View Image</button>
              </div>
            </div> */}
                </div>
                {/* <div className="attribute-card">
              <div className="attribute-label">Image</div>
              <div>
                <button onClick={handleOpenImage} className="view-btn">View Image</button>
              </div>
            </div> */}
              </div>

              {/* {showFullImage && (
            <div className="image-card">
              <div className="d-flex justify-content-center">
                <div className="image-header">
                  <button className="close-button" onClick={handleCloseImage}>
                    &#10005;
                  </button>
                </div>
                <div className="image-content">
                  <img src={imageData} alt="Full Image" />
                </div></div>
            </div>
          )} */}
            </div>
           
            <div className="coupon-card cards_coupons">
              <div className="coupon-data-container">
                <div className="row text-center f-1"> <h3>Order Details</h3></div>

                <div className="attribute-card">
                  <div className="attribute-label">Order id</div>
                  <div className="attribute-value">{couponData?.order_id?couponData.order_id:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Order Number</div>
                  <div className="attribute-value">{couponData?.order_number?couponData.order_number:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Order Quantity</div>
                  <div className="attribute-value">{couponData?.order_quantity?couponData.order_quantity:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Almond Voucher Code</div>
                  <div className="attribute-value">{couponData?.coupon_code?couponData.coupon_code:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Almond Voucher Pin</div>
                  <div className="attribute-value">{couponData?.almond_pin?couponData.almond_pin:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Expire At</div>
                  <div className="attribute-value">{couponData?.expireAt?formatDate(couponData.expireAt) :"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Redeem Date</div>
                  <div className="attribute-value">{couponData?.redeem_date?formatDate(couponData.redeem_date):"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Status</div>
                  <div className="attribute-value">{convertStatusCode( couponData?.status)}</div>
                </div>
                <hr />
                <div className="attribute-card">
                  <div className="attribute-label">Reference No.</div>
                  <div className="attribute-value">{couponData?.refno?couponData.refno:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Card Number</div>
                  <div className="attribute-value">{couponData?.cardNumber?couponData.cardNumber:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Card Pin</div>
                  <div className="attribute-value">{couponData?.cardPin?couponData.cardPin:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Card Id</div>
                  <div className="attribute-value">{couponData?.cardId?couponData.cardId:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Activation URL</div>
                  <div className="attribute-value">{couponData?.activationUrl?couponData.activationUrl:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Activation Code</div>
                  <div className="attribute-value">{couponData?.activationCode?couponData.activationCode:"N/A"}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Order Date</div>
                  <div className="attribute-value">{couponData?.created_at?formatDate(couponData.created_at) :"N/A"}</div>
                </div>
                {/* <div className="attribute-card">
              <div className="attribute-label">Image</div>
              <div>
                <button onClick={handleOpenImage} className="view-btn">View Image</button>
              </div>
            </div> */}
              </div>

              {/* {showFullImage && (
            <div className="image-card">
              <div className="d-flex justify-content-center">
                <div className="image-header">
                  <button className="close-button" onClick={handleCloseImage}>
                    &#10005;
                  </button>
                </div>
                <div className="image-content">
                  <img src={imageData} alt="Full Image" />
                </div></div>
            </div>
          )} */}
            </div>
          </div>
          <div className="coupon_row">
            <div className="coupon-card cards_coupons">
              <div className="coupon-data-container">
                {console.log("hsjdsjsdbvj", couponData)}

                <div className="row text-center f-1"> <h3>Customer Information</h3></div>
                
                <div className="attribute-card">
                  <div className="attribute-label">User Id</div>
                  <div className="attribute-value">{usersData?.id}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Name</div>
                  <div className="attribute-value">{usersData?.name}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Mobile</div>
                  <div className="attribute-value">{usersData?.mobile}</div>
                </div>
                <div className="attribute-card">
                  <div className="attribute-label">Status</div>
                  {console.log("status", usersData?.status)}
                  <div className="attribute-value">{usersData?.status === 1?"Active":"Inactive "}</div>
                </div>
              </div>

            </div>
            <div className="coupon-card cards_coupons">
              <div className="coupon-data-container">
           
            <div className="attribute-card">
              {/* <div className="attribute-label">Image</div> */}
              <div>
                <img className="object-fit-cover border rounded" src={couponData?.image} alt="coupon image" style={{maxWidth:"100%"}}/>
                {/* <button onClick={handleOpenImage} className="view-btn">View Image</button> */}
              </div>
            </div>
          </div>

              {showFullImage && (
                <div className="image-card">
                  <div className="d-flex justify-content-center">
                    <div className="image-header">
                      <button className="close-button" onClick={handleCloseImage}>
                        &#10005;
                      </button>
                    </div>
                    <div className="image-content">
                      <img src={imageData} alt="Full Image" />
                    </div></div>
                </div>
              )}
            </div>
          </div>
         
       

    </div>
  );
}

export default CouponCard;
