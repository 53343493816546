import axios from "axios";
import { baseUrl } from "../constant";

export const getAmountCouponStatus = async (page, limit) => {
    try {
        const response = await axios.get(`${baseUrl}/api/admin/coupons-status?type=MASTERCARD&page=${page}&limit=${limit}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        
        return response;
    } catch (err) {
        return err;
    }
}